import React from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from "@mui/material/CircularProgress";
import { isNullUndefinedEmpty } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { API } from './../services/api/api';

/**
 *
 *
 * @export
 * @param {*} {onSelect}
 * @return {*} 
 */
export default function AutoCompleter({onSelect}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const navigate = useNavigate();


  const handleInputChange  = async value => {
    const substances = await API.getFilteredData(value.target.value);
    //console.log(substances);
    setOptions(substances);  
  };

  const getLabel = (option) => {
    if(option.name && option.cas){
      return option.name + " (" + option.cas + ")";
    }else{
      return option.name;
    }
  }

  const handleSelect = (event, value) => {
    // console.log(value);
    if(!isNullUndefinedEmpty(value)){
      onSelect(value);
      navigate("/ids/"+value._key);
    }
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="ac"
      clearOnEscape
      style={{ width: 400 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option._key === value._key}
      getOptionLabel={option => getLabel(option)}
      options={options}
      loading={loading}
      onInputChange={handleInputChange}
      onChange={handleSelect}
      renderInput={params => (
        <TextField
          {...params}
          label="search..."
          variant="outlined"
          // onChange={(ev,nv) => {
          //   // dont fire API if the user delete or not entered anything
          //   if (ev.target.value !== "" || ev.target.value !== null) {
          //     handleInputChange(ev.target.value);
          //   }
          // }}

          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
}
