import './dashboard.css';
import { API } from "./../../services/api/api";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SpeedIcon from '@mui/icons-material/Speed';
import { Typography } from '@mui/material';
import { CognitoAuth } from '../../auth/cognito';
import { calculateCompletionPercentage, isObjectEmpty } from '../../utils/utils';
import { useParams, useNavigate } from 'react-router-dom';


const columns = [
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'super-app-theme--header',
    //   width: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'cas',
      headerName: 'CAS',
      headerClassName: 'super-app-theme--header',
    //   width: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'completion',
      headerName: 'Completion',
      headerClassName: 'super-app-theme--header',
    //   width: 150,
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" value={Math.round(params.row.completion)} />
          <Box
            sx={{
              top: 1,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round(params.row.completion)}%`}
            </Typography>
          </Box>
        </Box>
      ),
    },
  ];

export default function Dashboard({user, onSelect}) {

    const navigate = useNavigate();

    const [rows, setRows] = React.useState([]);

    useEffect(() => {

        const fetchData = async () => {
            if(isObjectEmpty(user)){
              user = await CognitoAuth.getCurrentUser();
            } 

            const result = await API.getFlaggedComponents(user.username);
            // console.log(result);
            const mapped = result ? result : [];
            setRows(mapped);
        };  
            
      fetchData();
    }, []);

    const handleRowClick = async (params) => {
      const substance = await API.getPropertyData("_key", params.row._key);
      onSelect(substance[0]);
      navigate("/ids/"+substance[0]._key);
    };


    return (
        <>
            {rows ? (
                <Box sx={{'& .super-app-theme--header': {
                    backgroundColor: 'rgba(207,222,208, 0.55)',
                  },}}>
                    <Card>
                        <CardHeader
                            avatar={
                                <SpeedIcon sx={{ width: '50px', height: '50px' }} />
                            }
                            title={<Typography style={{ textAlign: 'left' }} variant="h5">My Dashboard</Typography>}
                        />
                        <CardContent>
                            <DataGrid 
                                getRowId={(row) => row._key}
                                rows={rows}
                                onRowClick={handleRowClick}
                                autoHeight
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                            />
                        </CardContent>
                    </Card>
                </Box>
            ) : (
                <CircularProgress />
            )}
        </>
    );
}