import './search.css';
import { API } from "./../../services/api/api";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

const columns = [
    {
      field: 'cas',
      headerName: 'CAS Number',
      headerClassName: 'super-app-theme--header',
    //   width: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'super-app-theme--header',
    //   width: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'categories',
      headerName: 'Categories',
      headerClassName: 'super-app-theme--header',
    //   width: 150,
      flex: 1,
      editable: false,
    },
    // {
    //   field: 'date',
    //   headerName: 'Date',
    //   headerClassName: 'super-app-theme--header',
    //   description: 'Last modified date',
    //   flex: 1,
    //   editable: false,
    // },
    {
    field: 'status',
    headerName: 'Status',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    //   width: 150,
    editable: false,
    },
  ];

export default function Search({onSelect}) {

    const navigate = useNavigate();

    const [rows, setRows] = React.useState([]);
    const {category} = useParams();

    useEffect(() => {
        
        const fetchData = async () => {
            const result = await API.searchByCategory(category);  
            const mapped = result.map(x => ({_key: x._key, cas: x.cas, name: x.name, categories: (x.categories).toString(), date: x.date, author: x.author, status: x.edit.edit ? "in modification by \"" + x.edit.user + "\"" : "available" })).sort((a, b) => a.name.localeCompare(b.name));
            setRows(mapped);
        };   
        
        fetchData();
    }, []);

    const handleRowClick = async (params) => {
        const substance = await API.getPropertyData("_key", params.row._key);
        onSelect(substance[0]);
        navigate("/ids/"+substance[0]._key);
    };

    return (
        <>
            {rows ? (
                <Box sx={{'& .super-app-theme--header': {
                    backgroundColor: 'rgba(207,222,208, 0.55)',
                  },}}>
                    <Card>
                        <CardHeader
                            avatar={
                                <SearchIcon sx={{ width: '50px', height: '50px' }} />
                            }
                            title={<Typography style={{ textAlign: 'left' }} variant="h5">Search...<>{category ? (<Typography>(filter by "{category}")</Typography>) : null}</> </Typography>}
                        />
                        <CardContent>
                            <DataGrid 
                                getRowId={(row) => row._key}
                                rows={rows}
                                onRowClick={handleRowClick}
                                autoHeight
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                disableRowSelectionOnClick
                            />
                        </CardContent>
                    </Card>
                </Box>
            ) : (
                <CircularProgress />
            )}
        </>
    );
}