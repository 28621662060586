import React from 'react';
import { TextField, ThemeProvider, createTheme } from '@mui/material';
import { styled } from '@mui/system';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    error: {
      main: '#f44336',
    },
    text: {
      primary: '#000000', // Black color for active state
      disabled: '#757575', // Grey color for disabled state
    },
  },
});

const CustomTextField = styled(TextField)(({ theme, hasValue }) => ({
  '& .MuiInputLabel-root': {
    fontWeight: hasValue ? 'normal' : 'bold',
  },
  '& .MuiInputLabel-root.Mui-disabled': {
    fontWeight: 'bold',
  },
  '& .MuiInputLabel-shrink': {
    transform: hasValue ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 20px) scale(1)',
  },
}));

const HighlightedTextField = ({ value, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <CustomTextField
        {...props}
        value={value}
        hasValue={Boolean(value)}
        variant="outlined"
        InputLabelProps={{
          shrink: Boolean(value),
        }}
      />
    </ThemeProvider>
  );
};

export default HighlightedTextField;
