import './libraries.css';
import { Typography } from '@mui/material';
import { Card, CardHeader, CardContent } from '@mui/material';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuBookIcon from '@mui/icons-material/MenuBook';

export default function Libraries() {
  return (
    <div className='Libraries'>
        <Card key="main_libraries">
            <CardHeader
                // avatar={<InsightsIcon fontSize="large" />}
                title={<Typography variant="h4">REFERENCES</Typography>}
            />
            <CardContent>
                <Card className="Card_websites" key="main_websites">
                    <CardHeader
                        avatar={<LibraryBooksIcon fontSize="large" />}
                        title={<Typography variant="h4">Websites</Typography>}
                    />
                    <CardContent>
                        <ol style={{ listStyleType: 'disc' }}>
                            <li><a target="_blank" rel="noreferrer" href="https://pubchem.ncbi.nlm.nih.gov/">PubChem</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://echa.europa.eu/information-on-chemicals">ECHA Information on Chemicals</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://chem.echa.europa.eu/">ECHA CHEM Database</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://www.efsa.europa.eu/en/microstrategy/openfoodtox">EFSA OpenFoodTox Database</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://apps.who.int/food-additives-contaminants-jecfa-database/">JECFA Database</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://www.cir-safety.org/ingredients">Cosmetic Ingredient Review</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://www.ema.europa.eu/en/search/search?">European Medicines Agency</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://www.osha.gov/">Occupational Safety and Health Administration (OSHA)</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/">PubMed</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://health.ec.europa.eu/other-pages/health-sc-basic-page/final-opinion-additives-used-tobacco-products_en">SCENIHR Final Opinion on Additives used in tobacco products</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=celex%3A32008R1334">Regulation (EC) No 1334/2008 on flavourings and certain food ingredients with flavouring properties for use in and on foods </a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=celex%3A32008R1334">Regulation (EU) No 1169/2011 on the provision of food information to consumers</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://www.foodpackagingforum.org/">Food Packaging Forum</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://ifrafragrance.org/safe-use/library">IFRA Standards Library</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://www.femaflavor.org/flavor-library">FEMA Flavor Ingredient Library</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://www.flavordata.com/grl/select#">IOFI Global Reference List</a></li>
                        </ol>
                        <br />
                        <ol style={{ listStyleType: 'disc' }}>
                            <li><a target="_blank" rel="noreferrer" href="https://echa.europa.eu/information-on-chemicals/annex-vi-to-clp">Table of harmonised entries in Annex VI to CLP</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://monographs.iarc.who.int/list-of-classifications">IARC List of Classification </a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://ntp.niehs.nih.gov/whatwestudy/assessments/cancer/roc">NTP Report on Carcinogens</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://oehha.ca.gov/proposition-65/proposition-65-list">Proposition 65 List</a></li>
                        </ol>
                        <br />
                        <ol style={{ listStyleType: 'disc' }}>
                            <li><a target="_blank" rel="noreferrer" href="https://rifmdatabase.rifm.org/rifmweb/home/home-page">RIFM Database (restricted access)</a></li>
                            <li><a target="_blank" rel="noreferrer" href="https://chemical-search.toxplanet.com/#/login">Toxplanet (restricted access)</a></li>

                        </ol>
                        <br />
                        <ol style={{ listStyleType: 'disc' }}>
                            <li><a target="_blank" rel="noreferrer" href="https://pmicloud.sharepoint.com/teams/InSilicoToxicologyAssessmentITTools-LifeSciences-Region/SitePages/Solutions.aspx">In Silico Toxicology Assessment IT Tools - Life Sciences (internal restricted access)</a></li>
                        </ol>
                    </CardContent>
                </Card>

                <Card className="Card_books" key="main_books">
                    <CardHeader
                        avatar={<MenuBookIcon fontSize="large" />}
                        title={<Typography variant="h4">General Library</Typography>}
                    />
                    <CardContent>
                        <ol style={{ listStyleType: 'disc' }}>
                            <li><a target="_blank" rel="noreferrer" href="https://pmicloud.sharepoint.com/:f:/r/teams/SILVER-SFPProductSafetyAssessment-CH/Shared%20Documents/SILVER%20General%20Library?csf=1&web=1&e=6X3iQ9">SILVER General Library</a></li>
                        </ol>
                    </CardContent>
                </Card>
            </CardContent>
        </Card>
    </div>
  );
}