import './user.css';
import * as React from 'react';
import { useEffect } from 'react';
import { CognitoAuth } from './../../auth/cognito';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Avatar, Card, CardContent, CardHeader, Typography } from '@mui/material';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

export default function User({user}) {

    const [user_role, setUserRole] = React.useState({});

    useEffect(() => {
        const fetchUserRole = async () => {
            const u_r = await CognitoAuth.getUserRole();  
            setUserRole(u_r);

            //console.log(user, user_role);
        };  
        
        fetchUserRole();
    }, []);

    return (
        <Card className="User" key="user_card" sx={{
                width: 320,
                maxWidth: '100%',
                boxShadow: 'lg',
            }}>
            {/* <CardHeader sx={{ alignItems: 'left', textAlign: 'left' }}
                avatar={<AccountCircleIcon fontSize="large" />}
                title={<Typography variant="h4">User Informations</Typography>}
            /> */}
            <CardContent sx={{ alignItems: 'center', textAlign: 'center' }}>
                <Avatar
                    src={user.signInUserSession ? user?.signInUserSession?.idToken?.payload?.picture : null}
                    sx={{ width: 56, height: 56, ml:14, mb:2 }}
                />
                <Typography level="title-lg">{user_role.user}</Typography>
                <Typography level="body-sm">{user.signInUserSession ? user?.signInUserSession?.idToken?.payload?.email : null}</Typography>
                <Typography level="body-sm">{user_role.roles ? user_role?.roles.toString() : null}</Typography>
            </CardContent>
        </Card>
    );
}