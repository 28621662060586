import { toast } from 'react-toastify';
import { CognitoAuth } from '../auth/cognito';

export function isNullUndefinedEmpty(value) {
    //console.log(value);
    if(value === undefined || value === null || value === "" || (value.isArray ? value.length === 0 : false)){
      return true;
    }else{
      return false;
    }
}

export function isEmptyObject(object) {
    for (var key in object) {
        if (object.hasOwnProperty(key)) {
            return false;
        }
    }

    return true;
}

export function compareJSON(obj1, obj2) {
  let differences = {};
  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);
  for (let key of keys1) {
      if (keys2.indexOf(key) === -1) {
          differences[key] = obj1[key];
      } else if (!isNullUndefinedEmpty(obj1[key]) && !isNullUndefinedEmpty(obj2[key]) && typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {

          let nestedDifferences = compareJSON(obj1[key], obj2[key]);
          if (Object.keys(nestedDifferences).length > 0) {
            if(key === "containers"){
                differences[key] = obj2.containers;
            }else{
                differences[key] = nestedDifferences;
            }
          }
      } else if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {

          differences[key] = obj2[key];
      }
  }
  for (let key of keys2) {
      if (keys1.indexOf(key) === -1) {
          differences[key] = obj2[key];
      }
  }

  return differences;
}

export function hasOwnNestedProperty(ref, propertyPath) {
    // console.log(ref, propertyPath);

    if (!propertyPath)
        return false;

    let properties = propertyPath.split('.');
    let obj = ref;

    for (var i = 0; i < properties.length; i++) {
        let prop = properties[i];

        if (!obj || !obj.hasOwnProperty(prop)) {
            return false;
        } else {
            obj = obj[prop];
        }
    }

    return true;
};

export function traverseAndFlattenCustom(currentNode, target, flattenedKey) {
    for (var key in currentNode) {
        if (currentNode.hasOwnProperty(key) && (key !== "containers" && key !== "synonyms" && key !== "categories" && key !== "alternative_cas")) {
            var newKey;
            if (flattenedKey === undefined) {
                newKey = key;
            } else {
                newKey = flattenedKey + '.' + key;
            }

            var value = currentNode[key];
            if (typeof value === "object") {
                traverseAndFlattenCustom(value, target, newKey);
            } else {
                target[newKey] = value;
            }
        }else{
            if(key === "containers"){
                // if(isNullUndefinedEmpty(target[key])){
                //     target[key] = [];
                // }

                target.containers = currentNode[key].map(x => x.name + " (" + x.cas + ")" ).toString();
            }else{
                //console.log(key);
                target[key] = currentNode[key].toString();
            }

        }
    }
}

export function hasOwnNestedPropertyList(obj, propertyList) {

    if (!propertyList)
        return false;

    let result = true;  

    for(var j = 0; j < propertyList.length; j++) {
        let properties = propertyList[j].split('.');

        for (var i = 0; i < properties.length; i++) {
            let prop = properties[i];

            if (!obj || !obj.hasOwnProperty(prop)) {
                result = false;
            } else {
                obj = obj[prop];
                result = true;
            }
        }

        if(result){
            return true;
        }
    }

    return false;
};

export function parseObjectIntoString(obj){
    let result = "(";
    for(const[key, value] of Object.entries(obj)){
        if(key !== "_key"){
            result += `${key} : ${value}, `;
        }
    }
    result += ")\n";
    return result;
}

export function compareArray(arr1, arr2){
    const removed = arr1.filter(item => !arr2.includes(item));
    const added = arr2.filter(item => !arr1.includes(item));

    return { removed:removed, added:added };
}

export function isObjectEmpty(objectName) {
    return Object.keys(objectName).length === 0 && objectName.constructor === Object;
}

export async function isAllowedMaintainer(user){
    let maintainer_role = "Maintainer"
    let name_roles = await CognitoAuth.getUserRole();
    //console.log(name_roles.roles.includes(maintainer_role));
    return name_roles.roles.includes(maintainer_role);
}

export async function isAllowed(user){
    let read_only_role = "Read Only"
    let name_roles = await CognitoAuth.getUserRole();
    //console.log(!name_roles.roles.includes(read_only_role));
    return !name_roles.roles.includes(read_only_role);
}

export function isEditable(user, object){
    if(!isNullUndefinedEmpty(object) && !isNullUndefinedEmpty(user)){
        if((object.user === user.username || object.user === null) && object.edit === true){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
}

export function isNCS(categories){
    if(categories !== null && categories.length > 0){
        if(categories.includes("Natural Complex Substance")){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
}

export function getObjectValueByKey(object, key){
    //console.log(object, key);
    if(object && typeof object === 'object'){
        if(key in object){
            //console.log(object[key]);
            return object[key];
        }
    }

    return 0;
}

export function countCategories(object) {
    try{
        let { result, total, sum } = object.reduce(
            ({ result, total, sum }, o) => ({
                result: !isNullUndefinedEmpty(o.categories) ? o.categories.reduce(
                    (acc, categorie) => ({ ...acc, [categorie]: (acc[categorie] || 0) + 1 }),
                    result
                ) : 0,
                total: total + (!isNullUndefinedEmpty(o.categories) ? o.categories.length : 0),
                sum: sum + 1,
            }),
                { result: {}, total: 0, sum: 0 }
            );
            
        let f_result = { ...result, sum: sum, total: total };

        f_result.material = Object.entries(f_result).reduce((acc, [key, value]) => {
        if (key.startsWith("Material")) {
            return acc + value;
        } else {
            return acc;
        }}, 0);
        //console.log(f_result);
        return f_result;  
    }catch(ex){
        toast.error("ERROR ! impossible to count compounds !");
        return {sum:0, material:0, total:0};
    }
}

export function findObjectByPropertyValue(obj, propName, propValue) {
    try{
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];

                if (key === propName && value === propValue) {
                    return obj;
                }

                if (typeof value === 'object') {
                    const result = findObjectByPropertyValue(value, propName, propValue);
                    if (result) {
                        return result;
                    }
                }
            }
        }
        return null; // Property not found in the object
    }catch(ex){
        return null;
    }
}

export function parsePubchemObjectValue(obj, type){
    try{
        const value = obj.Information[0].Value.StringWithMarkup.map(x => x[type]);
        if(value.length === 1){
            return value[0];
        }else{
            return value;
        }
    }catch(ex){
        return "";
    }  
}

export function parsePubchemIUPACName(obj, type){
    try{
        //console.log(obj, type);
        if(!isNullUndefinedEmpty(obj))
        {
            const section = obj.Section.find(x => x["TOCHeading"] === "IUPAC Name");
            if(!isNullUndefinedEmpty(section)){
                const value = section.Information[0].Value.StringWithMarkup.map(x => x[type]);
                if(!isNullUndefinedEmpty(value)){
                    return value[0];
                }
            }
        }

        return "";
    }catch(ex){
        return "";
    }  
}

export function parsePubchemBoilingPoint(obj, type){
    try{
        // console.log(obj);
        const value = obj?.Information.map(x => (!isNullUndefinedEmpty(x.Reference) ? x?.Reference.toString().concat(" : ", (x?.Value.StringWithMarkup ? x?.Value?.StringWithMarkup[0]?.String : x?.Value?.Number[0].toString().concat(x?.Value.Unit))) : (x?.Value.StringWithMarkup ? x?.Value?.StringWithMarkup[0]?.String : x?.Value?.Number[0].toString().concat(x?.Value.Unit))));
        // console.log(value);
        return value.toString();
    }catch(ex){
        // console.log(ex);
        return "";
    }  
}


export function calculateCompletionPercentage(obj) {

    // console.log(obj);

    let totalProperties = 0;
    let completedProperties = 0;
   
    function evaluateObject(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && (key !== "containers" && key !== "composition_source" && key !== "edit" && key !== "botanical_name" && key !== "botanical_family" && key !== "botanical_category" && key !== "ncs_processing" && key !== "plant_family_name")) {
          //console.log(key);      
          const value = obj[key];
   
          if (isObject(value)) {
            evaluateObject(value);
          } else {
            totalProperties++;
  
            if (isFilled(value)) {
              completedProperties++;
            }
          }
        }
      }
    }
   
    function isObject(value) {
      return typeof value === 'object' && value !== null;
    }
   
    function isFilled(value) {
      return value !== "" && value !== 0 && !(Array.isArray(value) && value.length === 0);
    }
   
    evaluateObject(obj);
   
    if (totalProperties === 0) {
      return 100; // Handle the case where the object is empty
    }
   
    return ((completedProperties / totalProperties) * 100).toFixed(2);
  }

export function checkMissingFields(obj) {
    //console.log(obj);

    const fields = [
        'value', 'unit', 'animals', 'exposure_hours_per_day', 'exposure_day_per_week', 'animal_route_administration',
        'UFDi', 'UFDii',
        'IFRA_cat_6', 'NESIL', 'applicable_TTC'
    ];

    const getPaths = (obj, path = '') => {
        let paths = [];
        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                paths = paths.concat(getPaths(obj[key], path ? `${path}.${key}` : key));
            } else {
                paths.push(path ? `${path}.${key}` : key);
            }
        }
        return paths;
    };
    
    const findFieldPaths = (obj, fields) => {
        const allPaths = getPaths(obj);
        return fields.map(field => allPaths.filter(path => path.endsWith(field)));
    };

    const getValueByPath = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };
    
    const fieldPaths = findFieldPaths(obj, fields);
    //console.log(fieldPaths);
    let result = {check:false, keys:[]};

    for(const path of fieldPaths){
        for(const p of path){
            let value = getValueByPath(obj, p);
            if(value === "" || value === 0){
                result.check = true;
                result.keys.push(getLabel(p));
            }
        }
    }

    return result;
} 

export function getEmptyPlatform(){
    return {
        "name": "",
        "product_daily_max_item_use": 0,
        "product_daily_max_unit_use": 0,
        "mass_item": 0,
        "transfer_ratio":0 ,
        "safety_factor": 0,
        "contact_surface": 0,
        "oral": false,
        "inhalation": false,
        "SAF": 0,
        "retention_rate": 0
      }
}

export function getEmptyCompound() {
    return {
        "cas": "", //+
        "name": "", //+
        "IUPAC_name": "",
        "initial_reviewer": "",
        "categories": [],
        "composition_source" : "",
        "alternative_cas": [], //+
        "edit": {user: null, edit: false},
        "creation_date": new Date().toLocaleString() + "",
        "last_updated": new Date().toLocaleString() + "",
        "approval_status": "Not approved",
        "synonyms": [], //+
        "containers": [],  //+
        "calculate_MRL_by_containers": false,
        "chem_id": {
          "CLP_health_harmo_class": "",  //+
          "CRAMER_class": "", //+
          "CRAMER_class_ref": "", //+
          "FEMA_number": 0, //+
          "FEMA_safety_status": "", //+
          "FL_number": "",  //+
          "IARC_class": "", //+
          "JECFA_number": "" , //+
          "JECFA_status": "", //+
          "regulation_annexe": "", 
          "NTP_class": "",  //+
          "mol_formula": "",  // PUBCHEM
          "mol_weight": 0,  // PUBCHEM
          "boiling_point": 0, // PUBCHEM ? 
          //"pmi_funcID": "",  //+
          //"pmi_regID": "", //+
          "prop_65": "",  //+
          "canonical_smiles": "", // PUBCHEM
          "isomeric_smiles": "", // PUBCHEM
          "chem_group": "",  // - 
          "food_contact_status": "",  // - 
          "report_PCT": false,
          "rodgman_perfetti": false,
          "ink_toolbox": "",
          "literature": "",
          "highest_disclosed_percentage": 0,
          "test_done_passed": 0,
          "botanical_name": "",
          "plant_family_name": "",
          "botanical_category": "",
          "ncs_processing": "",
          //"ordinance": ""
        },
        "tox_data": {
          "comment": "",
          "ADME": "", //+
          "local_effect": {      
            "respiratory_tract_irritation": "",  //+
            "skin_irritation": "",  //+
            "eye_irritation": "",  //+
            "other_local_effect": "",  //+
          },
          "sensitization_tolerance": {
             "respiratory_sensitization": "",
             "respiratory_sensitization_alert": "",
             "skin_sensitization": "",
             "skin_sensitization_alert": "",
             "oral_allergy_intolerance": "",
             "IFRA_status": "",
             "IFRA_cat_1": 0,
             "IFRA_cat_6": 0,
             "IFRA_recommendation_comment": "",
             "NESIL": 0,
             "NESIL_comment": "",
            //  "AEL": 0,
          },
          "PMI_flavor_toolbox_study": {
            "systemic_ADE": 0,
            "local_resp_ADE": 0,
          },
          "general_toxicity": {
            "acute_toxicity": "",
            "repeated_dose_toxicity": {
                "inhalation": "",
                "oral": "",
                "dermal": "",
            },
            "CMR_alerts": 0,
            "genotoxicity": "",
            "carcinogenicity": "",
            "reproductive_developmental_toxicity": "",
            "cardiopulmonary_effects": "",
            "other_revelant_toxicity": "",
          },
          //"substance_highconcern_nonvaporised_status": "",
          //"chemistry_toxicity_emissions": "",
          "addictivness_attractiveness" : "",
          "SCENIHR" : "",
          "in_silico_data" : "",
          "inhalation_exposure": {
            "applicable_TTC": 0,
            "toxicity": {
                "POD": {
                    "type_effect" : "", // LOCAL OR SYSTEMIC
                    "study_test_method": "",
                    "animal_route_administration": "",
                    "value": 0,
                    "unit": "",
                    "dose_descriptor": "",
                    "GLP_compliance": "",
                    "animals": "",
                    "study_duration": "",
                    "read_across": {
                        "is_read_across" : false,
                        "material_cas": "",
                        "material_name": "", 
                        "material_mw": "", 
                        "material_attachment": "", // FILE                     
                    },
                    "exposure_hours_per_day": 0,
                    "exposure_day_per_week": 0,
                    "effects_description": "",
                    "effects_severity": "",
                    "study_ref": "",

                },
                "uncertainty_factors": {
                    //"allometric_scaling": 1,
                    //"UFA": 0,
                    //"UFH": 0,
                    //"UFL": 0,
                    //"UFS": 0,
                    "UFDi": 0,
                    "UFDi_comments": "",
                    "UFDii": 0,
                    "UFDii_comments": "",
                    //"overall_UF": 0, // calculated
                    "UF_comments": "", 
                },
            },
          },
          "oral_exposure": {
            "applicable_TTC": 0,
            "ADI": 0,
            "ADI_ref": "",
            "toxicity": {
                "POD": { 
                    "type_effect" : "", // LOCAL OR SYSTEMIC
                    "study_test_method": "",
                    "animal_route_administration": "",
                    "value": 0,
                    "unit": "", 
                    "dose_descriptor": "",
                    "GLP_compliance": "", 
                    "animals": "",
                    "study_duration": "",
                    "read_across": {
                        "is_read_across" : false,
                        "material_cas": "",
                        "material_name": "",
                        "material_mw": "", 
                        "material_attachment": "", // FILE                     
                    },
                    "exposure_hours_per_day": 0,
                    "exposure_day_per_week": 0,
                    "effects_description": "",
                    "effects_severity": "",
                    "study_ref": "",
                },
                "uncertainty_factors": {
                    //"allometric_scaling": 0,
                    //"UFA": 0,
                    //"UFH": 0,
                    //"UFL": 0,
                    //"UFS": 0,
                    "UFDi": 0,
                    "UFDi_comments": "",
                    "UFDii": 0,
                    "UFDii_comments": "",
                    //"overall_UF": 0, // calculated
                    "UF_comments": "",                
                },
            }
          }
        }
      };
}

const conversionTable = {
    "cas": "CAS",
    "name": "Name",
    "IUPAC_name": "IUPAC Name",
    "initial_reviewer": "Initial Reviewer",
    "categories": "Categories",
    "composition_source": "Composition Source",
    "alternative_cas": "Alternative CAS",
    "creation_date": "Creation Date",
    "last_updated": "Last Updated",
    "approval_status": "Approval Status",
    "synonyms": "Synonyms",
    "containers": "Containers",
    "calculate_MRL_by_containers": "Calculate MRL by Containers",
    "chem_id.CLP_health_harmo_class": "CLP Health Harmo Class",
    "chem_id.CRAMER_class": "Cramer Class",
    "chem_id.CRAMER_class_ref": "Cramer Class Ref",
    "chem_id.FEMA_number": "FEMA Number",
    "chem_id.FEMA_safety_status": "FEMA Safety Status",
    "chem_id.FL_number": "FL Number",
    "chem_id.IARC_class": "IARC Class",
    "chem_id.JECFA_number": "JECFA Number",
    "chem_id.JECFA_status": "JECFA Status",
    "chem_id.regulation_annexe": "Regulation Annexe",
    "chem_id.NTP_class": "NTP Class",
    "chem_id.mol_formula": "Molecular Formula",
    "chem_id.mol_weight": "Molecular Weight",
    "chem_id.boiling_point": "Boiling Point",
    "chem_id.prop_65": "Prop 65",
    "chem_id.canonical_smiles": "Canonical SMILES",
    "chem_id.isomeric_smiles": "Isomeric SMILES",
    "chem_id.chem_group": "Chemical Group",
    "chem_id.food_contact_status": "Food Contact Status",
    "chem_id.report_PCT": "Report PCT",
    "chem_id.rodgman_perfetti": "Rodgman Perfetti",
    "chem_id.ink_toolbox": "Ink Toolbox",
    "chem_id.literature": "Literature",
    "chem_id.highest_disclosed_percentage": "Highest Disclosed Percentage",
    "chem_id.test_done_passed": "Test Done Passed",
    "chem_id.botanical_name": "Botanical Name",
    "chem_id.plant_family_name": "Plant Family Name",
    "chem_id.botanical_category": "Botanical Category",
    "chem_id.ncs_processing": "NCS Processing",
    "tox_data.comment": "Comment",
    "tox_data.ADME": "ADME",
    "tox_data.local_effect.respiratory_tract_irritation": "Respiratory Tract Irritation",
    "tox_data.local_effect.skin_irritation": "Skin Irritation",
    "tox_data.local_effect.eye_irritation": "Eye Irritation",
    "tox_data.local_effect.other_local_effect": "Other Local Effect",
    "tox_data.sensitization_tolerance.respiratory_sensitization": "Respiratory Sensitization",
    "tox_data.sensitization_tolerance.respiratory_sensitization_alert": "Respiratory Sensitization Alert",
    "tox_data.sensitization_tolerance.skin_sensitization": "Skin Sensitization",
    "tox_data.sensitization_tolerance.skin_sensitization_alert": "Skin Sensitization Alert",
    "tox_data.sensitization_tolerance.oral_allergy_intolerance": "Oral Allergy Intolerance",
    "tox_data.sensitization_tolerance.IFRA_status": "IFRA Status",
    "tox_data.sensitization_tolerance.IFRA_cat_1": "IFRA Category 1",
    "tox_data.sensitization_tolerance.IFRA_cat_6": "IFRA Category 6",
    "tox_data.sensitization_tolerance.IFRA_recommendation_comment": "IFRA Recommendation Comment",
    "tox_data.sensitization_tolerance.NESIL": "NESIL",
    "tox_data.sensitization_tolerance.NESIL_comment": "NESIL Comment",
    "tox_data.PMI_flavor_toolbox_study.systemic_ADE": "Systemic ADE",
    "tox_data.PMI_flavor_toolbox_study.local_resp_ADE": "Local Respiratory ADE",
    "tox_data.general_toxicity.acute_toxicity": "Acute Toxicity",
    "tox_data.general_toxicity.repeated_dose_toxicity.inhalation": "Repeated Dose Toxicity (Inhalation)",
    "tox_data.general_toxicity.repeated_dose_toxicity.oral": "Repeated Dose Toxicity (Oral)",
    "tox_data.general_toxicity.repeated_dose_toxicity.dermal": "Repeated Dose Toxicity (Dermal)",
    "tox_data.general_toxicity.CMR_alerts": "CMR Alerts",
    "tox_data.general_toxicity.genotoxicity": "Genotoxicity",
    "tox_data.general_toxicity.carcinogenicity": "Carcinogenicity",
    "tox_data.general_toxicity.reproductive_developmental_toxicity": "Reproductive Developmental Toxicity",
    "tox_data.general_toxicity.cardiopulmonary_effects": "Cardiopulmonary Effects",
    "tox_data.general_toxicity.other_revelant_toxicity": "Other Relevant Toxicity",
    "tox_data.addictivness_attractiveness": "Addictiveness Attractiveness",
    "tox_data.SCENIHR": "SCENIHR",
    "tox_data.in_silico_data": "In Silico Data",
    "tox_data.inhalation_exposure.applicable_TTC": "Applicable TTC",
    "tox_data.inhalation_exposure.toxicity.POD.type_effect": "PoD Type Effect",
    "tox_data.inhalation_exposure.toxicity.POD.study_test_method": "PoD Study Test Method",
    "tox_data.inhalation_exposure.toxicity.POD.animal_route_administration": "PoD Animal Route Administration",
    "tox_data.inhalation_exposure.toxicity.POD.value": "Inhalation PoD Value",
    "tox_data.inhalation_exposure.toxicity.POD.unit": "Inhalation PoD Unit",
    "tox_data.inhalation_exposure.toxicity.POD.dose_descriptor": "Inhalation PoD Dose Descriptor",
    "tox_data.inhalation_exposure.toxicity.POD.GLP_compliance": "Inhalation PoD GLP Compliance",
    "tox_data.inhalation_exposure.toxicity.POD.animals": "Inhalation PoD Animals",
    "tox_data.inhalation_exposure.toxicity.POD.study_duration": "Inhalation PoD Study Duration",
    "tox_data.inhalation_exposure.toxicity.POD.read_across.is_read_across": "Inhalation PoD Read Across",
    "tox_data.inhalation_exposure.toxicity.POD.read_across.material_cas": "Inhalation PoD Material CAS",
    "tox_data.inhalation_exposure.toxicity.POD.read_across.material_name": "Inhalation PoD Material Name",
    "tox_data.inhalation_exposure.toxicity.POD.read_across.material_mw": "Inhalation PoD Material MW",
    "tox_data.inhalation_exposure.toxicity.POD.read_across.material_attachment": "Inhalation PoD Material Attachment",
    "tox_data.inhalation_exposure.toxicity.POD.exposure_hours_per_day": "Inhalation PoD Exposure Hours Per Day",
    "tox_data.inhalation_exposure.toxicity.POD.exposure_day_per_week": "Inhalation PoD Exposure Days Per Week",
    "tox_data.inhalation_exposure.toxicity.POD.effects_description": "Inhalation PoD Effects Description",
    "tox_data.inhalation_exposure.toxicity.POD.effects_severity": "Inhalation PoD Effects Severity",
    "tox_data.inhalation_exposure.toxicity.POD.study_ref": "Inhalation PoD Study Reference",
    "tox_data.inhalation_exposure.toxicity.uncertainty_factors.UFDi": "Inhalation UF(D)i",
    "tox_data.inhalation_exposure.toxicity.uncertainty_factors.UFDi_comments": "Inhalation UF(D)i Comments",
    "tox_data.inhalation_exposure.toxicity.uncertainty_factors.UFDii": "Inhalation UF(D)ii",
    "tox_data.inhalation_exposure.toxicity.uncertainty_factors.UFDii_comments": "Inhalation UF(D)ii Comments",
    "tox_data.inhalation_exposure.toxicity.uncertainty_factors.UF_comments": "Inhalation Uncertainty Factor Comments",
    "tox_data.oral_exposure.applicable_TTC": "Oral Exposure Applicable TTC",
    "tox_data.oral_exposure.ADI": "Oral Exposure ADI",
    "tox_data.oral_exposure.ADI_ref": "Oral Exposure ADI Reference",
    "tox_data.oral_exposure.toxicity.POD.type_effect": "Oral PoD Type Effect",
    "tox_data.oral_exposure.toxicity.POD.study_test_method": "Oral PoD Study Test Method",
    "tox_data.oral_exposure.toxicity.POD.animal_route_administration": "Oral PoD Animal Route Administration",
    "tox_data.oral_exposure.toxicity.POD.value": "Oral PoD Value",
    "tox_data.oral_exposure.toxicity.POD.unit": "Oral PoD Unit",
    "tox_data.oral_exposure.toxicity.POD.dose_descriptor": "Oral PoD Dose Descriptor",
    "tox_data.oral_exposure.toxicity.POD.GLP_compliance": "Oral PoD GLP Compliance",
    "tox_data.oral_exposure.toxicity.POD.animals": "Oral Animals",
    "tox_data.oral_exposure.toxicity.POD.study_duration": "Oral PoD Study Duration",
    "tox_data.oral_exposure.toxicity.POD.read_across.is_read_across": "Oral PoD Read Across",
    "tox_data.oral_exposure.toxicity.POD.read_across.material_cas": "Oral PoD Material CAS",
    "tox_data.oral_exposure.toxicity.POD.read_across.material_name": "Oral PoD Material Name",
    "tox_data.oral_exposure.toxicity.POD.read_across.material_mw": "Oral PoD Material MW",
    "tox_data.oral_exposure.toxicity.POD.read_across.material_attachment": "Oral PoD Material Attachment",
    "tox_data.oral_exposure.toxicity.POD.exposure_hours_per_day": "Oral PoD Exposure Hours Per Day",
    "tox_data.oral_exposure.toxicity.POD.exposure_day_per_week": "Oral PoD Exposure Days Per Week",
    "tox_data.oral_exposure.toxicity.POD.effects_description": "Oral PoD Effects Description",
    "tox_data.oral_exposure.toxicity.POD.effects_severity": "Oral PoD Effects Severity",
    "tox_data.oral_exposure.toxicity.POD.study_ref": "Oral PoD Study Reference",
    "tox_data.oral_exposure.toxicity.uncertainty_factors.UFDi": "Oral UF(D)i",
    "tox_data.oral_exposure.toxicity.uncertainty_factors.UFDi_comments": "Oral UF(D)i Comments",
    "tox_data.oral_exposure.toxicity.uncertainty_factors.UFDii": "Oral UF(D)ii",
    "tox_data.oral_exposure.toxicity.uncertainty_factors.UFDii_comments": "Oral UF(D)ii Comments",
    "tox_data.oral_exposure.toxicity.uncertainty_factors.UF_comments": "Oral Uncertainty Factor Comments"
};

function getLabel(key) {
    return conversionTable[key] || key;
}


