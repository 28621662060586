
import { Auth } from 'aws-amplify';
import {CognitoUser} from "@aws-amplify/auth";
import jwtDecode from "jwt-decode";

let user = CognitoUser | null;

export const CognitoAuth = {

    getTokenPropertyByKeyName: function(token, keyName){
        try{
            const decodedToken = jwtDecode(token);
            return decodedToken[keyName] || null;
        } catch(er){
            throw new Error(er);
        }
    },

    extractValuesFromString(input) {
        if (input == null) return [];
    
        const prefix = "PMIGlobalURSILVER";
        const suffixes = ["DEVIGA", "QAIGA", "PRDIGA"];
    
        const values = [];
    
        const regexPattern = new RegExp(`${prefix}(.*?)(${suffixes.join('|')})`, 'g');
        const matches = input.match(regexPattern);
    
        if (matches) {
            for (const match of matches) {
                const value = match.replace(prefix, '').replace(new RegExp(`(?:${suffixes.join('|')})$`), '');
                values.push(value);
            }
        }
    
        return values;
    },

    extractValuesFromArray(inputArray) {
        const values = [];
    
        for (const input of inputArray) {
            const extractedValues = this.extractValuesFromString(input);
            values.push(...extractedValues);
        }
    
        return values;
    },

    extractValues(input) {

        if (input == null) return [];
    
        if (typeof input === 'string') {
            return this.extractValuesFromString(input);
        }else {
            return this.extractValuesFromArray(input);
        }
    },

    getHeaders: async function(url, headers){ 
        const tokenId = await this.getCurrentUserTokenId();

        // FETCH
        // if ((url.startsWith('https://silver-api') || url.startsWith('http://localhost')) && tokenId) {
        //     headers = {
        //         ...headers,
        //         Authorization: tokenId,
        //     };
        // }

        // return headers;

        // AXIOS
        const config = {
            headers: headers,
        };

        if ((url.startsWith('https://silver-api') || url.startsWith('http://localhost')) && tokenId) {
            config.headers = {
                ...config.headers,
                Authorization: tokenId,
            };
        }

        return config;
    },

    getCurrentUser: async function(){
        
        if (user) {
            return user;
        }
    
        try {
            user = await Auth.currentAuthenticatedUser();
            //console.log(user);
            return user;
        } catch (error) {
            console.log('Error getting current user:', error);
            await Auth.federatedSignIn();
            return null;
        }
    },

    getUserRole: async function(){

        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            const token = currentUser.getSignInUserSession()?.getIdToken().getJwtToken();
            if (token) {
                const name = this.getTokenPropertyByKeyName(String(token), 'name')
                const roles = this.extractValues(this.getTokenPropertyByKeyName(String(token), 'custom:groups'));
                const roles2 = this.extractValues(this.getTokenPropertyByKeyName(String(token), 'cognito:groups'));

                return { user: name, roles: roles.concat(roles2) };
            } else {
                throw new Error('User session not found');
            }
            
        } catch (error) {
            console.error('Error retrieving user role:', error);
        }
    },

    getCurrentUserTokenId: async function() {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            const session = currentUser.getSignInUserSession();
            // console.log(currentUser);
            // console.log(session);
            if (session) {
                const tokenId = session.getIdToken().getJwtToken();
                return tokenId;
            } else {
                throw new Error('User session not found');
            }
        } catch (error) {
            console.log('Error getting current user token ID:', error);
            return null;
        }
    },

    signOut: async function() {
        try{
            await Auth.signOut();
        } catch (error) {
            console.log('Sign out error:', error);
        }
    }
}