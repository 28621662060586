import { useEffect } from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

function Prompt(props) {
  const block = props.when;

  useBlocker(() => {
    if (block) {
      return !window.confirm(props.message);
    }
    return false;
  });

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (block) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    const handleUnload = () => {
      if (block) {
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [block]);

  return (
    <div key={block} />
  );
}

export default Prompt;