import React, { useState } from 'react';
import { Box, Button, Modal, TextField } from '@mui/material';

export const CASNumberModal = ({ open, onClose, onOk }) => {
    const [casNumbers, setCasNumbers] = React.useState('');
    const [error, setError] = useState('');

    const handleOk = () => {
        const casArray = casNumbers.split(';').map(cas => cas.trim());
        onOk(casArray);
        setCasNumbers("");
        onClose();
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setCasNumbers(value);
        const isValid = value.split(';').every(cas => /^\d{2,7}-\d{2}-\d$/.test(cas.trim()));
        setError(isValid ? '' : 'Invalid CAS number format ! (should be : XXXXX-XX-X;...)');
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: '#fff', padding: '20px', borderRadius: '8px' }}>
                <Box>
                    <TextField
                            label="List of CAS Numbers"
                            multiline
                            fullWidth
                            value={casNumbers}
                            // onChange={(e) => setCasNumbers(e.target.value)}
                            onChange={handleChange}
                            //inputProps={{ pattern: '^\\d{2,7}-\\d{2}-\\d$', title: 'Enter CAS numbers in the format XXXX-XX-X' }}
                            error={!!error}
                            helperText={error}
                        />
                    <Button variant="contained" onClick={handleOk} style={{ marginTop: '10px' }} disabled={!!error}>
                            OK
                    </Button>
                </Box>
            </div>
        </Modal>
    );
};