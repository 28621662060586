import Dashboard from '../dashboard/dashboard';
import Notification from '../notification/notification';
import Summary from '../summary/summary';
import './home.css';

export default function Home({user, onSelect}) {

  const handleSelect = (value) => {
    onSelect(value);
  };

  return (
    <div className='Home'>
      <br/>
      <Summary />
      <br/>
      <Dashboard user={user} onSelect={handleSelect}/>
      <br/>
      <Notification user={user}/>
    </div>
  );
}