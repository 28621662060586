import './App.css';
import LeftDrawer from './layouts/drawer.js'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const handleValue = (value) => {
  //console.log(`User selected [APP]: ${value}`);
};

function App() {
  return (
    <div>
      <ToastContainer style={{ width: "25%"}}/>
      <LeftDrawer onValue={handleValue}/>
    </div>
  );
}

export default App;
